import React, { useState, useEffect, useContext } from "react";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import HeaderBtn from "../../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import AllContext from "../../AllContext";
import { set } from "date-fns";

const THead = () => {
  const [visitId, setVisitId] = useState("asc");
  const [checkInDate, setCheckInDate] = useState("asc");
  const [patientId, setPatientId] = useState("asc");
  const [createdAt, setCreatedAt] = useState("asc");
  const [payment, setPayment] = useState("asc");
  const [visitType, setVisitType] = useState("asc");

  const { handleHeaderQuery, handleFilterQuery, setIsFiltered, showLive } =
    useContext(AllContext);

  async function visitTypeSort() {
    if (showLive) {
      handleHeaderQuery("visit_type_id", visitType);
      setVisitType(visitType === "asc" ? "desc" : "asc");
      return;
    }
  }

  async function visitIdSort() {
    setIsFiltered(false);

    if (showLive) {
      handleHeaderQuery("id", visitId);
      setVisitId(visitId === "asc" ? "desc" : "asc");
      return;
    }
  }

  async function patientSort() {
    if (showLive) {
      handleHeaderQuery("patient_id", patientId);
      setPatientId(patientId === "asc" ? "desc" : "asc");
      return;
    }
  }

  async function createdAtSort() {
    if (showLive) {
      handleHeaderQuery("created_at", createdAt);
      setCreatedAt(createdAt === "asc" ? "desc" : "asc");
      return;
    }
  }

  async function paymentSort() {
    if (showLive) {
      handleHeaderQuery("default_payment_method", payment);
      setPayment(payment === "asc" ? "desc" : "asc");
    }
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort w={100 / 8} click={visitIdSort} label="VISIT-ID" />
      <HeaderBtnSort w={100 / 8} click={visitTypeSort} label="VISIT-TYPE" />
      <HeaderBtnSort click={paymentSort} w={100 / 8} label="PAYMENT METHOD" />
      <HeaderBtnSort w={100 / 8} click={createdAtSort} label="CHECK-IN DATE" />
      <HeaderBtnSort w={100 / 8} click={patientSort} label="PATIENT" />
      <HeaderBtn w={100 / 8} label="CURRENTLY" />
      <HeaderBtn w={100 / 8} label="FROM" />
      <HeaderBtn w={100 / 8} label="TO" />
    </TableHeaderRow>
  );
};

export default THead;
