import React, { useState, useEffect } from "react";
import { BsCaretRightSquare } from "react-icons/bs";
import { SlOptionsVertical } from "react-icons/sl";

import BasicDropDown from "../../../DropLib2/BasicDropDown";

const HorizonatalActionBtn = ({
  children,
  next = () => {},
  showOptions = true,
  showNext = true,
  hide = 0,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (hide === 0) return;
    setIsVisible(false);
  }, [hide]);

  return (
    <div className="flex  flex-row gap-x-4 text-2xl  text-cadet_grey">
      {showNext ? (
        <p className=" hover:text-primary cursor-pointer" onClick={next}>
          <BsCaretRightSquare />
        </p>
      ) : null}
      {showOptions ? (
        <p
          className="hover:text-primary   cursor-pointer"
          onClick={() => setIsVisible(!isVisible)}
        >
          <SlOptionsVertical />
        </p>
      ) : null}
      <div className=" absolute " style={{ right: "0em", marginTop: "1.4em" }}>
        <BasicDropDown isVisible={isVisible}>{children}</BasicDropDown>
      </div>
    </div>
  );
};

export default HorizonatalActionBtn;
