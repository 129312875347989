import { colors } from "./../../Util/colors";

const Table = ({ children, bg = "white" }) => {
  return (
    <div
      className="px-4 relative py-4 shadow-sm  rounded-xl w-full overflow-x-auto h-full"
      style={{ backgroundColor: colors[bg] }}
    >
      {children}
    </div>
  );
};

export default Table;
