import React, { useContext, useState } from "react";

import { TableHeaderRow } from "../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

const THead = () => {
  const { handleHeaderQuery } = useContext(AllContext);

  const [order, setOrder] = useState("asc");

  function handleSort(name) {
    handleHeaderQuery(name, order);
    setOrder(order === "asc" ? "desc" : "asc");
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort
        w={95 / 8}
        label="VISIT-ID"
        click={() => handleSort("id")}
      />
      <HeaderBtnSort w={95 / 8} label="CHECK-IN DATE" />
      <HeaderBtnSort w={95 / 8} label="PATIENT" />
      <HeaderBtnSort
        w={95 / 8}
        label="WAITING AREA"
        click={() => handleSort("user_id")}
      />
      {/* <HeaderBtnSort w={95 / 8} label="FROM" /> */}
      <HeaderBtnSort
        w={95 / 8}
        label="ATTENDING"
        click={() => handleSort("is_attending")}
      />
      <HeaderBtnSort w={95 / 8} label="PRIORITY" />
      <HeaderBtnSort
        w={95 / 8}
        label="SPACE"
        click={() => handleSort("space_id")}
      />
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
