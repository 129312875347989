import { useState, useEffect } from "react";
import {
  TableRow,
  TableCell,
  TableText,
  
} from "../../../../../../../Components/FinalTable";
import Badge from "../../../../../../../Components/Badges/Badge";

import usePouch from "../../../../../../../Hooks/usePouch";

import { convDate2 } from "../../../../../../../Util/Time";

const TRow = ({ doc }) => {
  const [itemName, setItemName] = useState("......");
  const { fetchById } = usePouch();

  useEffect(() => {
    async function initialize() {
      if (doc.is_inventory) {
        try {
          let inv = await fetchById({
            db: "inventory_items",
            id: doc.inventory_item_id.toString(),
          });
          
          setItemName(inv.name);
        } catch (e) {}
        return;
      }
      try {
        let fixed = await fetchById({
          db: "fixed_assets",
          id: doc.fixed_assets_id.toString(),
        });
        
        setItemName(fixed.name);
      } catch (e) {}
    }

    initialize();
  }, []);

  return (
    <TableRow mt={10} key={doc._id}>
      <TableCell w={100 / 5}>
        <TableText label={(doc.is_inventory ? doc.inventory_items.name : doc.fixed_assets.name) || itemName} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={doc.is_inventory ? "Inventory" : "Fixed Asset"} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={doc.qty} />
      </TableCell>
      <TableCell w={100 / 5}>
        <TableText label={convDate2(doc.created_at)} />
      </TableCell>
      <TableCell w={100 / 5}>
        <Badge
          label={doc.status}
          bg={doc.status === "approved" ? "pale_spring_bud" : doc.status === "pending" ? "pale_pink" : "pale_pink"}
        />
      </TableCell>
      
    </TableRow>
  );
};

export default TRow;
