import SingleStepper from "./SingleStepper";

import { FaTimes } from "react-icons/fa";

const Steppers = ({ children, currentStep, close }) => {
  return (
    <div className="flex flex-row items-center justify-between bg-bright_grey ">
      <div className="flex w-fit">
        {children.map((child, i) => (
          <SingleStepper key={i} currentStep={currentStep} stepNo={i}>
            {child}
          </SingleStepper>
        ))}
      </div>
      <div
        className="text-xl font-DM-Reg flex items-center w-fit text-auro_metal_saurus cursor-pointer"
        onClick={close}
      >
        <p className="">
          <FaTimes />
        </p>
        <p className=" ml-4 mr-5">CANCEL</p>
      </div>
    </div>
  );
};

export default Steppers;
