import TableInfo from "../../../../../../../Components/FinalTable/components/TableInfoRow";

import Pagination from "../../../../../../../Components/FinalTable/components/Pagination";

import InfoCell from "../../../../../../../Components/FinalTable/components/InfoCell";

const Infotbl =({data})=>{
    return(
        <div>
            <TableInfo mt={20}>
                <InfoCell
                    limit={10}
                    docs={data}
                    setLimit={function noRefCheck() {}}
                />
                <Pagination/>
            </TableInfo>

        </div>
    )
};

export default Infotbl;