import React, { useState, useEffect, useContext } from "react";

import {
  TableHeaderRow,
  TableHeaderBtn,
} from "../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

import usePouch from "./../../../../../../../Hooks/usePouch";

const vtype = [1,2,3,4]

const THead = () => {
  const { handleHeaderQuery, showLive, handleFilterQuery, setIsFiltered } =
    useContext(AllContext);

  const [checkIn, setCheckIn] = useState(true);
  const [checkout, setCheckOut] = useState(true);
  const [visitTypeNo, setVisitTypeNo] = useState(0);
  const [visitTypes, setVisitTypes] = useState(true);
  const [payment, setPayment] = useState(true);
  const [checkInDate, setCheckInDate] = useState(true);

  function handleCheckInDateSort() {
    if (showLive) {
          handleHeaderQuery("created_at", checkInDate ? "asc" : "desc");
          setCheckInDate(!checkInDate);
          setIsFiltered(true);
          return;
        }
      
      const index = ["created_at"];
      const sort = [{ created_at: checkInDate ? "asc" : "desc"}];
      const selector = { created_at: { $gte: null } };
    
      setCheckInDate(!checkInDate);
      
      handleHeaderQuery(index, sort, selector);
  }

  function handlePaymentFilter() {
    if (showLive) {
          handleHeaderQuery("default_payment_method", payment ? "asc" : "desc");
          setPayment(!payment);
          setIsFiltered(true);
          return;
        }
      
      const index = ["default_payment_method"];
      const sort = [{ default_payment_method: payment ? "asc" : "desc"}];
      const selector = { default_payment_method: { $eq: null } };
    
      const secondarySelector = {
        default_payment_method: {
          $eq: payment ? "Cash" : "Insurance",
        },
      };
  
      handleFilterQuery(index, sort, selector, secondarySelector);

      setPayment(!payment);
  }

  function handleStatusFilter() {
    if (showLive) {
          handleHeaderQuery("checked_out", checkout ? "asc" : "desc");
          setCheckOut(!checkout);
          setIsFiltered(true);
          return;
        }
      
      const index = ["checked_out"];
      const sort = [{ checked_out: checkout ? "asc" : "desc"}];
      const selector = { checked_out: { $eq: null } };
    
      const secondarySelector = {
        checked_out: {
          $eq: checkout,
        },
      };
  
      handleFilterQuery(index, sort, selector, secondarySelector);

      setCheckOut(!checkout);
  }

  function handleVisitTypeFilter() {
    if (showLive) {
      handleHeaderQuery("visit_type_id", visitTypes ? "asc" : "desc");
      setVisitTypes(!visitTypes);
      setIsFiltered(true);
      return;
    }

    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };

    const secondarySelector = {
      visit_type_id: {
        $eq: visitTypeNo > vtype.length - 1 ? vtype[0] : vtype[visitTypeNo],
      },
    };

    if (visitTypes > vtype.length - 1) {
      setVisitTypes(0);
    } else {
      setVisitTypes((c) => c + 1);
    }
    handleFilterQuery(index, sort, selector, secondarySelector);

    setIsFiltered(true);
  }


  
  return (
    <TableHeaderRow>
      <TableHeaderBtn w={95 / 5} label="CHECKED IN BY" />
      <HeaderBtnSort
        w={95 / 5}
        click={handleCheckInDateSort}
        label="CHECK-IN DATE"
      />
      <HeaderBtnSort
        w={95 / 5}
        click={handleVisitTypeFilter}
        label="VISIT TYPE"
      />
      <HeaderBtnSort w={95 / 5} click={handlePaymentFilter} label="PAYMENT" />
      <HeaderBtnSort w={95 / 5} click={handleStatusFilter} label="STATUS" />
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
