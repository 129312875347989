import React, { useContext, useEffect, useRef, useState } from "react";
import BasicModal from "./../../../../../Components/Modals/BasicModal";

import usePouch from "../../../../../Hooks/usePouch";

import TextArea from "../../../../../Components/Inputs/TextBox";

import TContext from "../VisitStep/TContext";

const ViewStep = ({ doc, showModal, setShowModal }) => {
  const { receptionist } = useContext(TContext);

  const [created_by, setCreated_by] = useState({});

  const input = useRef({ form: {}, err: {} });

  const { fetchById } = usePouch();

  useEffect(() => {
    if (showModal === false) return;
    async function initialize() {
      try {
        let created = await fetchById({
          db: "team",
          id: doc.user_id.toString(),
        });
        setCreated_by(created);
      } catch (e) {
        setShowModal(false);
      } finally {
      }
    }

    initialize();
  }, []);
  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Step Created by
          <span className=" font-DM-Bold"> {receptionist.name}</span>{" "}
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <TextArea
            initial={doc.comments}
            input={input}
            inputKey="comments"
            mt={0.5}
            config={{
              required: false,
              disabled: true,
              label: "Comments",
              placeholder: "",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />
        </form>
      </div>
    </BasicModal>
  );
};

export default ViewStep;
