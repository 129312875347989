import BtnPlain from "../../Buttons/BtnPlain";

const TableTabs = ({ activeTab = "", tabs = [], handleTab = null }) => {
  return (
    <div className="w-full flex flex-row gap-x-2 mb-3 items-start">
      {tabs &&
        Array.isArray(tabs) &&
        tabs.map((tab, index) => {
          return (
            <BtnPlain
              key={index}
              activeBgColor="white"
              activeTextColor="primary"
              textColor={tab.color ? tab.color : "primary"}
              click={() => handleTab(tab)}
            >
              <div className={`flex flex-col gap-1 items-center justify-start`}>
                <h3 className="font-DM-Med text-lg font-medium">
                  {tab.title}{" "}
                  <span className=" font-DM-Bold">
                    {tab.no ? `( ${tab.no} )` : null}
                  </span>
                </h3>
                {activeTab === tab.title && (
                  <span
                    className="w-3/4 bg-primary rounded-sm h-[2px] "
                    style={{ marginTop: "-5px" }}
                  ></span>
                )}
              </div>
            </BtnPlain>
          );
        })}
    </div>
  );
};

export default TableTabs;
