import ItemDetails from "./ItemDetails";

import AddInv from "./AddInv";
import AddFixed from "./AddFixed";

const Forms = ({ step, setStep, itemType }) => {
  if (step === -1) {
    return <ItemDetails setStep={setStep} />;
  }

  return itemType === "inv" ? <AddInv /> : <AddFixed />;
};

export default Forms;
