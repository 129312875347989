import React, { useState } from "react";
import { colors } from "./../../Util/colors";

const BtnRounded = ({
  bg = "primary",
  bgActive = "primary_accent",
  textColor = "white",
  mt = 0,
  mb = 0,
  click,
  children,
}) => {
  const [bgColor, setBgColor] = useState(bg);

  function handleMouseDown() {
    setBgColor(bgActive);
  }

  function handleMouseUp() {
    setBgColor(bg);
  }

  return (
    <div
      className={`rounded-lg h-fit cursor-pointer  flex flex-row justify-center items-center  px-4 py-2 active:opacity-50 font-DM-Bold text-base mt-5`}
      onClick={click}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        backgroundColor: colors[bgColor],
        color: colors[textColor],
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      {children}
    </div>
  );
};

export default BtnRounded;
