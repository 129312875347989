import React, { useContext, useEffect, useState, useRef } from "react";
import TextInput from "../../../../../../Components/Inputs/TextInput";
import SelectInput from "../../../../../../Components/Inputs/SelectInput";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";
import NumberInput from "../../../../../../Components/Inputs/NumberInput";

import AddContext from "../AddContext";

import useHandleFormError from "../../../../../../Hooks/useHandleFormError";

import usePouch from "./../../../../../../Hooks/usePouch";

import useAxios from "../../../../../../Hooks/useAxios";

import useApp from "../../../../../../Hooks/useApp";

import { useNavigate } from "react-router-dom";

const NextOfKin = () => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const [relationships, setRelationships] = useState([]);

  const formErrors = useHandleFormError();

  const { patient, kin, setKin } = useContext(AddContext);

  const { setModalMessage, setShowSuccessModal } = useApp();

  const { findAll } = usePouch();

  const navigate = useNavigate();

  const request = useAxios();

  useEffect(() => {
    async function initialize() {
      let res = await request({
        method: "GET",
        url: "patient/kin/relationships",
        params: {
          page: 1,
          limit: 500,
        },
      });
      if (res === "error") {
        navigate("../");
        return;
      }

      let relations = res.docs.map((doc) => ({
        value: doc.id,
        label: doc.name,
      }));

      setRelationships([
        { value: "", label: "Select Relation Ship" },
        ...relations,
      ]);
    }

    initialize();
  }, []);

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    await setKin(input.current.form);

    let res = await request({
      method: "POST",
      url: "patient/kin",
      data: { ...input.current.form, patient_id: parseInt(patient.id) },
    });

    if (res === "error") return;

    setModalMessage("Next of Kin Added");
    setShowSuccessModal(true);
    navigate("../");
  }

  return (
    <div
      className=" m-auto p-10 shadow-md rounded-lg mt-5 bg-white"
      style={{ width: "60%" }}
    >
      <TextInput
        initial={kin.name || ""}
        input={input}
        showError={showError}
        inputKey="name"
        mt={0.5}
        config={{
          required: true,
          label: "Name",
          placeholder: "John Doe",
          maxChar: 250,
          minChar: 4,
        }}
      />

      <NumberInput
        initial={kin.phone || ""}
        input={input}
        showError={showError}
        inputKey="phone"
        mt={0.5}
        config={{
          required: false,
          label: "Phone",
          placeholder: "Enter phone number ",
          maxChar: 13,
          minChar: 6,
        }}
      />

      <SelectInput
        initial={patient.relationship || ""}
        input={input}
        showError={showError}
        inputKey="relationship"
        mt={0.5}
        config={{
          disabled: false,
          required: true,
          firstDisabled: false,
          items: relationships,
          label: "Select Relationship",
        }}
      />

      <SelectInput
        initial={patient.gender || ""}
        input={input}
        showError={showError}
        inputKey="gender"
        mt={0.5}
        config={{
          disabled: false,
          required: true,
          firstDisabled: false,
          items: [
            { label: "Select Gender", value: "" },
            { label: "Male", value: "Female" },
            { label: "Female", value: "Female" },
            { label: "Other", value: "Other" },
          ],
          label: "Select Gender",
        }}
      />
      <BtnRounded click={submit}>Save</BtnRounded>
    </div>
  );
};

export default NextOfKin;
