import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../Components/FinalTable";

import { MdSick } from "react-icons/md";

import { FaUserEdit } from "react-icons/fa";

import useAxios from "./../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";

const TableAction = ({ doc, visit }) => {
  const navigate = useNavigate();

  const request = useAxios();

  const { user, setModalMessage, setShowSuccessModal } = useApp();

  async function handleAttend() {
    let res = await request({
      method: "POST",
      url: "hospital/step/attend",
      data: {
        step_id: parseInt(doc.visit_step_id),
        user_id: parseInt(user.id),
      },
    });

    if (res === "error") return;

    setModalMessage("Now attending to this Patient");
    setShowSuccessModal(true);
    navigate(`/dashboard/next-step/${visit.id}`);
    // navigate(`/dashboard/next-step/${vstep.id}`);
  }
  return (
    <div className="flex-1">
      {/* <TableHorizontalActionBtn /> */}
      <ActionBtn next={handleAttend}>
        <div className=" gap-y-2 flex flex-col text-sm">
          <BtnPlain activeBgColor="white" bg="white" click={handleAttend}>
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-black">
                <FaUserEdit />
              </p>
              <p className=" ">Attend</p>
            </div>
          </BtnPlain>
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableAction;
