import React, { useState, useEffect } from "react";

import { colors } from "../../Util/colors";

const DateRangePicker = ({
  input,
  inputKey1,
  inputKey2,
  initial1,
  initial2,
  showError = 0,
  mt = 0,
  mb = 0,
  config = {
    required: true,
    disabled: false,
    label: "Select date",
    max: null,
    min: null,
  },
}) => {
  const [dateInput1, setDateInput1] = useState(initial1);
  const [dateInput2, setDateInput2] = useState(initial2);
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [err, setErr] = useState(false);
  const [errMessage, setErrorMessage] = useState("Field Required");

  useEffect(() => {
    if (showError === 0) {
      return;
    }
    handleBlur();
  }, [showError]);

  function handleChange1(date) {
    setDateInput1(date);
    input.current.form[inputKey1] = date;
  }

  function handleChange2(date) {
    setDateInput2(date);
    input.current.form[inputKey2] = date;
  }

  function handleBlur() {
    if (config.required === false) {
      return;
    }

    if (dateInput1 === "" || dateInput2 === "") {
      setBorderColor("red");
      setErr(true);
      setErrorMessage(`* Date is required`);
      return;
    }
    setBorderColor("bright_grey");
    setErr(false);
    setErrorMessage(`* Date required`);
  }

  return (
    <div
      className="flex flex-col"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <label className="font-DM-Med text-base">{config.label}</label>
      <div className="w-full flex items-center gap-x-4">
        <input
          className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey`}
          onClick={(e) => (config.disabled ? null : e.target.showPicker())}
          style={{
            borderColor: colors[borderColor],
            cursor: config.disabled ? "not-allowed" : "text",
          }}
          disabled={config.disabled}
          type={"date"}
          value={dateInput1}
          onBlur={handleBlur}
          onChange={(e) => handleChange1(e.target.value)}
          min={config.min}
        />
        -
        <input
          className={`border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey`}
          onClick={(e) => (config.disabled ? null : e.target.showPicker())}
          style={{
            borderColor: colors[borderColor],
            cursor: config.disabled ? "not-allowed" : "text",
          }}
          disabled={config.disabled}
          type={"date"}
          value={dateInput2}
          onBlur={handleBlur}
          onChange={(e) => handleChange2(e.target.value)}
          min={dateInput1}
          max={config.max}
        />
      </div>
      <small
        className="text-red font-DM-Reg"
        style={{ opacity: err ? "100%" : "0%" }}
      >
        {errMessage}
      </small>
    </div>
  );
};

export default DateRangePicker;
