import React from "react";
import Cart from "./Cart";
const CartModal = ({ children, cancel, showCart, setShowCart, add }) => {
  if (showCart) {
    return (
      <>
        <div className=" h-screen flex overflow-x-clip overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-fit mx-auto">
            <div className="border-0  shadow-lg relative flex flex-col w-full items-end  outline-none focus:outline-none">
              <div className="relative w-screen    flex-auto h-screen">
                <Cart cancel={cancel} setShowCart={setShowCart} add={add}>
                  {children}
                </Cart>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }
  return null;
};

export default CartModal;
