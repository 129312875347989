import React, { useEffect, useState } from "react";

import PouchDb from "pouchdb";

import { host, pass, user as couchUser } from "../../../conf";

import useApp from "../../../Hooks/useApp";

import usePouch from "../../../Hooks/usePouch";

const useCouchSync = () => {
  const { signal, setSignal, user } = useApp();

  const { deleteIndexes, deleteDB } = usePouch();

  const [st, setSt] = useState(false);

  useEffect(() => {
    async function init() {
      try {
        await deleteIndexes({ db: "signal" });
        await deleteDB({ db: "signal" });
      } catch (error) {
      } finally {
        setSt(true);
      }
    }
    init();
  }, []);

  useEffect(() => {
    const DB = new PouchDb("signal");
    const rep = DB.replicate
      .from(`https://${couchUser}:${pass}@${host}/signal`, {
        live: true,
        retry: true,
      })
      .on("change", async (doc) => {
        try {
          if (st === false) return;

          let docs = doc.docs;

          for (let doc of docs) {
            if (doc.table_name === "queue") {
              let users = doc.users_id;
              if (users.includes(user.id)) {
                let ns = signal;
                ns[doc.table_name] = ns[doc.table_name] + 1 || 1;
              }
              return;
            }

            if (doc.table_name) {
              let ns = signal;

              ns[doc.table_name] = ns[doc.table_name] + 1 || 1;

              setSignal(ns);
            }
          }
        } catch (e) {}
      })
      .on("paused", () => {
        //console.log("Paused Replication");
      })
      .on("active", () => {
        //console.log("Resumed Replication");
      })
      .on("denied", () => {
        //console.log("denied");
      })
      .on("complete", () => {
        //console.log("Completed Sync");
      })
      .on("error", (error) => {
        //console.log(error);
        //console.log("Rep Error");
      });

    return () => {
      rep.cancel();
    };
  }, [st]);
};

export default useCouchSync;
