import React, { createContext } from "react";
import HeaderBtn from "../../../../../Components/FinalTable/components/Btns/HeaderBtn";

import { TableHeaderRow } from "../../../../../Components/FinalTable";

const THead = () => {
  return (
    <div className="w-full">
      <TableHeaderRow>
        <HeaderBtn w={90 / 6} label="Step" />
        <HeaderBtn w={90 / 6} label="SPACE" />
        <HeaderBtn w={90 / 6} label="ATTENDANT" />
        <HeaderBtn w={90 / 6} label="COMMENTS" />
        <HeaderBtn w={90 / 6} label="PRIORITY" />
        <HeaderBtn w={90 / 6} label="STATUS" />

        <div style={{ width: "10%" }}></div>
      </TableHeaderRow>
    </div>
  );
};

export default THead;
