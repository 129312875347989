import React, { useEffect, useRef, useState } from "react";
import BasicModal from "./../../../../../Components/Modals/BasicModal";

import BtnBlock from "./../../../../../Components/Buttons/BtnBlock";

import { IoMdArrowForward } from "react-icons/io";

import useAxios from "../../../../../Hooks/useAxios";

import useApp from "../../../../../Hooks/useApp";

const Checkout = ({ id, showModal, setShowModal, fetchSteps, visitDoc }) => {
  const request = useAxios();

  const { user, setShowSuccessModal, setModalMessage } = useApp();

  async function submit() {
    let res = await request({
      method: "PUT",
      url: "hospital/checkout",
      data: {
        visit_id: parseInt(visitDoc.id),
        checked_out_by: parseInt(user.id),
      },
    });

    if (res === "error") return;
    await fetchSteps();
    setModalMessage("Patient Checked Out");
    setShowSuccessModal(true);
    setShowModal(false);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Checkout patient ?
        </h2>
        <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
          <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
            <span>Continue</span>
            <span className="text-xl">
              <IoMdArrowForward />
            </span>
          </div>
        </BtnBlock>
      </div>
    </BasicModal>
  );
};

export default Checkout;
