import { useRef, useState, useEffect } from "react";

import BasicModal from "../../../../../../../Components/Modals/BasicModal";
import useHandleFormError from "../../../../../../../Hooks/useHandleFormError";

import useAxios from "../../../../../../../Hooks/useAxios";

import { useNavigate, useParams } from "react-router-dom";

import BtnRounded from "../../../../../../../Components/Buttons/BtnRounded";

import TextInput from "../../../../../../../Components/Inputs/TextInput";
import SelectInput from "../../../../../../../Components/Inputs/SelectInput";

import NumberInput from "../../../../../../../Components/Inputs/NumberInput";

import usePouch from "../../../../../../../Hooks/usePouch";

import useApp from "../../../../../../../Hooks/useApp";

const EditKin = ({ showModal = false, setShowModal, kin, relations, init }) => {
  const input = useRef({ form: {}, err: {} });
  const [showError, setShowError] = useState(0);

  const navigate = useNavigate();

  const { setModalMessage, setShowSuccessModal } = useApp();

  const request = useAxios();

  const formErrors = useHandleFormError();

  const { id } = useParams();

  async function submit() {
    await setShowError(showError + 1);

    if (formErrors({ input })) return;

    let res = await request({
      method: "PUT",
      url: "patient/kin",
      data: { ...input.current.form, 
        relationship_id: parseInt(input.current.form.relationship), 
        patient_id: parseInt(id), 
        id: kin.id },
    });

    if (res === "error") return;

    await setModalMessage("Next of Kin Edited");
    await setShowSuccessModal(true);
    await setShowModal(false);
    await init();
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col  gap-y-8 p-3" style={{ width: "30em" }}>
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Edit Next Of Kin
        </h2>
        <div style={{ marginTop: "-2.5em" }}>
          <TextInput
            initial={kin.name || ""}
            input={input}
            showError={showError}
            inputKey="name"
            mt={0.5}
            config={{
              required: true,
              label: "Name",
              placeholder: "John Doe",
              maxChar: 250,
              minChar: 4,
            }}
          />

          <NumberInput
            initial={kin.phone || ""}
            input={input}
            showError={showError}
            inputKey="phone"
            mt={0.5}
            config={{
              required: true,
              label: "Phone",
              placeholder: "Enter phone number ",
              maxChar: 13,
              minChar: 6,
            }}
          />

          <SelectInput
            initial={kin.relationship_id || ""}
            input={input}
            showError={showError}
            inputKey="relationship"
            mt={0.5}
            config={{
              disabled: false,
              required: true,
              firstDisabled: false,
              items: relations,
              label: "Select Relationship",
            }}
          />

          <SelectInput
            initial={kin.gender || ""}
            input={input}
            showError={showError}
            inputKey="gender"
            mt={0.5}
            config={{
              disabled: false,
              required: true,
              firstDisabled: false,
              items: [
                { label: "Select Gender", value: "" },
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
                { label: "Other", value: "Other" },
              ],
              label: "Select Gender",
            }}
          />
        </div>
        <BtnRounded click={submit}>Save</BtnRounded>
      </div>
    </BasicModal>
  );
};

export default EditKin;
