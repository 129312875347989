import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { IoMdDoneAll } from "react-icons/io";

const CheckoutTip = ({ open = false, active = false, setShowCheckout }) => {
  const id = "checkout-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded bg="red" click={() => setShowCheckout(true)}>
        <IoMdDoneAll />
      </BtnRounded>
      <Tooltip id={id} txt1="Checkout" txt2="patient">
        <span className=" text-red">
          <IoMdDoneAll />
        </span>
      </Tooltip>
    </div>
  );
};

export default CheckoutTip;
