import React, { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";

import { convDate } from "./../../../../../../Util/Time";

import useAxios from "../../../../../../Hooks/useAxios";

const PatientDetailSection = ({}) => {
  const [patient, setPatient] = useState({});

  const { id } = useParams();

  const navigate = useNavigate();

  const request = useAxios();

  useEffect(() => {
    async function initialize() {
      let res = await request({ method: "GET", url: `patient/${id}` });

      if (res === "error") {
        navigate("../");
      }

      setPatient(res.patient);
    }

    initialize();
  }, []);

  return (
    <div
      className="bg-white px-10  overflow-y-auto rounded-tl-lg rounded-bl-lg"
      style={{ width: "40%", height: "90%" }}
    >
      <p className="font-bold text-xl text-gray-800 mb-6">Patient Profile</p>
      {patient.pic && (
        <img
          src={`data:image/jpeg;base64, ${patient.pic}`}
          className="px-auto"
          height={200}
          alt=""
        />
      )}

      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">NAME</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.first_name +
            " " +
            patient.second_name +
            " " +
            patient.sir_name}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">PHONE</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.phone}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">EMAIL</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.email}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">DOB</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {convDate(parseInt(patient.dob)) || "Uknown"}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">GENDER</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.gender}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">OCCUPATION</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.occupation}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">NIDA</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.id_no}
        </p>
      </div>
      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm"> WILAYA</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.district}
        </p>
      </div>

      <div className="mb-8 mt-3">
        <p className="font-semibold text-gray-500 text-sm">KATA</p>
        <p className="font-semibold text-gray-800 font-sans text-base">
          {patient.ward}
        </p>
      </div>
      <div style={{ height: "20px", width: "100%" }}></div>
    </div>
  );
};

export default PatientDetailSection;
