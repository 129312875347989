import PouchDB from "pouchdb";

const usePouchCud = () => {
  async function getTheDoc({ name, id }) {
    try {
      let db = new PouchDB(name);
      let doc = await db.get(id.toString());
      return doc;
    } catch (e) {
      return "error";
    }
  }

  async function create({ name, doc }) {
    try {
      let db = new PouchDB(name);
      let exists = await getTheDoc({ name, id: doc.id });

      if (exists !== "error") {
        return update({ name, doc });
      }

      let res = await db.put({
        _id: doc.id.toString(),
        ...doc,
      });
      return res;
    } catch (e) {
      return "error";
    }
  }

  async function update({ name, doc }) {
    try {
      const db = new PouchDB(name);
      let oldDoc = await getTheDoc({ name, id: doc.id || doc._id });

      if (oldDoc === "error") {
        return await db.put({
          _id: doc.id.toString(),
          ...doc,
        });
      }

      let res = await db.put({
        _id: doc.id.toString(),
        _rev: oldDoc._rev,
        ...doc,
      });
      return res;
    } catch (e) {
      return "error";
    }
  }

  async function deleteDoc({ name, doc }) {
    try {
      const db = new PouchDB(name);
      let oldDoc = await db.get(doc.id.toString() || doc._id.toString());
      let res = await db.remove(oldDoc._id, oldDoc._rev);
      return res;
    } catch (e) {
      return "error";
    }
  }

  return {
    create,
    update,
    deleteDoc,
  };
};

export default usePouchCud;
