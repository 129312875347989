import { BsCaretRightSquare } from "react-icons/bs";
import { SlOptionsVertical } from "react-icons/sl";

const HorizonatalActionBtn = ({}) => {
  return (
    <div className="flex flex-row gap-x-4 text-2xl  text-cadet_grey">
      <p className=" hover:text-primary cursor-pointer">
        <BsCaretRightSquare />
      </p>
      <p className="hover:text-primary   cursor-pointer">
        <SlOptionsVertical />
      </p>
    </div>
  );
};

export default HorizonatalActionBtn;
