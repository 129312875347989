import React, { useState, useEffect } from "react";
import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { FaUserInjured } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import useApp from "../../../../../Hooks/useApp";

const allowedTypes = [6, 7];

const AttendTip = ({ data, visitDoc }) => {
  const [show, setShow] = useState(false);

  const id = "attend-tooltip";

  const { user } = useApp();
  const navigate = useNavigate();

  useEffect(() => {
    if (!visitDoc) {
      setShow(false);
      return;
    }

    let visit_type = parseInt(visitDoc?.visit_type_id);

    if (!visit_type) {
      return;
    }

    if (!allowedTypes.includes(visit_type)) {
      setShow(false);
    }

    if (Array.isArray(data)) {
      for (let i = 0; i < data.length; i++) {
        let doc = data[i];

        if (doc?.currently_at) {
          let user_id = parseInt(doc?.user_id);
          if (user_id === parseInt(user.id)) {
            setShow(true);
            return;
          }
        }
      }
    }
  }, [data]);

  if (!data[0]) {
    return null;
  }

  // console.log(data);
  // console.log(user);
  // if (!data[0]?.currently_at) {
  //   return null;
  // }

  function handleAttend() {
    if (!Array.isArray(data)) {
      return;
    }
    if (data[0]?.id) {
      let id = data[0].id;
      console.log(id);
      navigate(`/dashboard/attend/${id}`);
    }
    return;
  }

  if (!show) return;

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded click={handleAttend}>
        <FaUserInjured />
      </BtnRounded>
      <Tooltip id={id} txt1="Add Investigation/Procedure" txt2="step">
        <FaUserInjured />
      </Tooltip>
    </div>
  );
};

export default AttendTip;
