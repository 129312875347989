import Account from '../../../../../../Assets/Images/rafiki2.png';

const PersonalInfo =()=>{
    return(
        <div className="w-[1137px] bg-white rounded-lg h-[276px] mt-10 shadow-md m-auto p-5">
            <div className=' flex justify-around '>
                <img src={Account} alt="Account"/>
                <div className='flex flex-col justify-between'>
                    <div>
                        <p className=' font-DM-Bold text-light_grey_ uppercase' >Insurance Company</p>
                        <p className=' font-DM-Reg'>Jubilee</p>
                    </div>
                    <div>
                        <p className=' font-DM-Bold text-light_grey_ uppercase'>Scheme</p>
                        <p className=' font-DM-Reg'>Jubilee</p>
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div>
                        <p className=' font-DM-Bold text-light_grey_ uppercase'>Principal Member</p>
                        <p className=' font-DM-Reg'>John Doe</p>
                    </div>
                    <div>
                        <p className=' font-DM-Bold text-light_grey_ uppercase'>Member number</p>
                        <p className=' font-DM-Reg'>98789900</p>
                    </div>
                </div>
                <div className='flex flex-col justify-between'>
                    <div>
                        <p className=' font-DM-Bold text-light_grey_ uppercase'>Member Name</p>
                        <p className=' font-DM-Reg'>John Doe</p>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default PersonalInfo;