import React, { useEffect } from "react";

import Modal from "./BasicModal";

import { BiError } from "react-icons/bi";

const FormModal = ({ showFormModal, setShowFormModal, modal_message }) => {
  return (
    <Modal showModal={showFormModal} setShowModal={setShowFormModal}>
      <div className=" p-2">
        <div className=" w-full flex flex-col items-center justify-center">
          <p className=" text-8xl animate-pulse text-red">
            <BiError />
          </p>
          <p className=" text-2xl mt-4 mb-4">{modal_message}</p>
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
