import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const HeaderBtnSort = ({ w, label, click }) => {
  return (
    <div
      className="flex flex-row justify-between items-center font-DM-Med text-base cursor-pointer"
      onClick={click}
      style={{ width: `${w}%`, color: "#8392AB" }}
    >
      <p className=" uppercase">{label}</p>
      <div className="flex flex-col" style={{ color: "#C7CCD0" }}>
        <IoIosArrowUp />
        <IoIosArrowDown />
      </div>
    </div>
  );
};

export default HeaderBtnSort;
