import React, { useEffect, useState, useContext } from "react";

import {
  TableRow,
  TableCell,
  MultiCheckBox,
  NumberInput,
  TableText,
} from "../../../../../Components/FinalTable";

import Badge from "../../../../../Components/Badges/Badge";

import { limitText } from "../../../../../Util/text";

import TActions from "./TActions";

const TRow = ({ doc }) => {
  function getSpaceType() {
    try {
      return doc.spaces.space_types.name;
    } catch (e) {
      //console.log(e);
      return "!! unknown";
    }
  }

  function getSpaceName() {
    try {
      return doc.spaces.name;
    } catch (e) {
      return "!! unknown";
    }
  }

  function AttendantName() {
    try {
      if (doc.user_id) {
        return doc.team_visit_steps_user_idToteam.name;
      }
      return "Any";
    } catch (e) {
      return "Any";
    }
  }

  function AttendantRole() {
    try {
      if (doc.user_id) {
        return "role: " + doc.team_visit_steps_user_idToteam.team_roles.role;
      }
    } catch (e) {
      return "";
    }
  }

  return (
    <TableRow>
      <TableCell w={90 / 6}>
        <TableText label={doc.step_no} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={getSpaceName()} />
        <Badge label={getSpaceType()} bg={"pale_pink"} />
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={AttendantName()} />
        {doc.user_id && <Badge label={AttendantRole()} bg={"pale_pink"} />}
      </TableCell>
      <TableCell w={90 / 6}>
        <TableText label={limitText(doc.comments, 20)} />
      </TableCell>
      <TableCell w={90 / 6}>
        <Badge
          label={doc.is_high ? "High" : "Normal"}
          bg={doc.is_high ? "pale_pink" : "bright_grey"}
        />
      </TableCell>
      <TableCell w={90 / 6}>
        {doc.currently_at === true ? (
          <Badge label="Current" bg="primary" textColor="white" />
        ) : (
          <Badge
            label={doc.is_complete ? "Complete" : "Pending"}
            bg={doc.is_complete ? "bright_grey" : "pale_pink"}
          />
        )}
      </TableCell>

      <div style={{ width: "10%" }} className=" ">
        <TActions doc={doc} />
      </div>
    </TableRow>
  );
};

export default TRow;
