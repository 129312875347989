import React, { useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../Components/FinalTable";

import TContext from "./TContext";

import SingleCheckBox from "../../../../../../Components/FinalTable/components/Actions/SingleCheckBox";
import MultiSelect from "../../Mulitple-Prescriptions/components/MultiSelect";

import CartMultiSelect from "./CartMultiSelect";

const TRow = ({ doc }) => {
  const { cartData, setCartData } = useContext(TContext);

  return (
    <TableRow key={doc?._id || ""}>
      <TableCell w={90 / 5}>
        <TableText label={doc?.name || ""} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc?.code || ""} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc?.regular_price || ""} />
      </TableCell>
      <TableCell w={90 / 5}>
        <TableText label={doc?.description || ""} />
      </TableCell>
      <TableCell>
        {/* <SingleCheckBox
          checked_id={checked_id}
          setCheckedId={setCheckedId}
          id={doc.id}
        /> */}
        <CartMultiSelect
          doc={doc}
          cartData={cartData}
          setCartData={setCartData}
        />
      </TableCell>
    </TableRow>
  );
};

export default TRow;
