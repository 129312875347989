import { colors } from "./../../Util/colors";

const Badge = ({
  bg = "pale_spring_bud",
  textColor = "primary",
  label = "label text",
  click = () => {},
  mt = 0.5,
}) => {
  return (
    <div
      onClick={click}
      style={{
        paddingVertical: 3,
        backgroundColor: colors[bg],
        color: colors[textColor],
        marginTop: `${mt}em`,
      }}
      className="rounded-md w-fit cursor-pointer flex flex-row justify-center items-center  px-2 font-pop-reg  text-base"
    >
      {label}
    </div>
  );
};

export default Badge;
