import React, { useState, useEffect } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../../Components/FinalTable";

import usePouchPagination from "../../../../../Hooks/usePouchPagination";

import useFuzzySearch from "../../../../../Hooks/useFuzzySearch";

import { useNavigate } from "react-router-dom";

import AllContext from "./AllContext";

import TableAll from "./Components/TableComponent/Table";

const AllPatients = ({ showLive }) => {
  const navigate = useNavigate();

  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "patients";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const searchOptions = {
    keys: ["first_name", "second_name", "sir_name", "email", "id_no", "phone"],
    minMatchCharLength: 3,
    threshold: 0.5,
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  useFuzzySearch({
    db_name,
    input: searchInput,
    options: searchOptions,
    limit,
    setShowPagination,
    setIsFiltered,
    setTableIsLoading,
    setData,
    fixed,
  });

  async function handleClearFilters() {
    await setFixed({});
    await resetQuery();

    setIsFiltered(false);
    setSearchInput("");
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total,
        setIsFiltered,
        showPagination,
        setFixed,
        resetQuery,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Patients" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            FilterClick={() => {}}
            mt={2}
            mb={2}
            addLabel="Add"
            showAdd={true}
            AddClick={() => navigate("add")}
            changes={0}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            searchInput={searchInput}
            setSearchInput={setSearchInput}
          />
          <TableAll />
        </div>
      </div>
    </AllContext.Provider>
  );
};
export default AllPatients;
