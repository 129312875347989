import CancelBar from "../../../../../Components/Steppers/CancelBar";

import FormSection from "./components/FormSection";

import PatientDetailSection from "./components/PatientDetailSection";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

const CheckIn = () => {
  return (
    <div className=" w-full h-full  bg-ghost_white overflow-clip">
      <BellTopBar label={`Check In`} />
      <CancelBar />
      <div className="w-full h-full items-center mt-5 flex flex-row justify-center  ">
        <div
          className=" bg-white  flex h-full  py-4 rounded-lg"
          style={{ width: "95%", height: "" }}
        >
          <PatientDetailSection />
          <FormSection />
        </div>
      </div>
    </div>
  );
};

export default CheckIn;
