import { colors } from "./../../Util/colors";

const PingBadge = ({
  bg = "pale_spring_bud",
  textColor = "primary",
  label = "label text",
  click,
}) => {
  return (
    <div
      style={{
        paddingVertical: 3,
        backgroundColor: colors[bg],
        color: colors[textColor],
      }}
      className="relative h-fit rounded-md w-fit cursor-pointer flex flex-row justify-center items-center  px-2 font-pop-reg  text-base"
      onClick={click}
    >
      <div className="absolute  top-1 right-1 -mr-1 -mt-1 w-2 h-2 rounded-full bg-pink animate-ping"></div>
      <div>{label}</div>
    </div>
  );
};

export default PingBadge;
