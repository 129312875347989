import React, { useEffect, useRef, useState } from "react";
import BasicModal from "../../../../../Components/Modals/BasicModal";

import usePouch from "../../../../../Hooks/usePouch";

import NumberInput from "../../../../../Components/Inputs/NumberInput";

import TextArea from "../../../../../Components/Inputs/TextBox";

import useApp from "../../../../../Hooks/useApp";

import useAxios from "../../../../../Hooks/useAxios";

import useHandleFormError from "../../../../../Hooks/useHandleFormError";

import BtnBlock from "../../../../../Components/Buttons/BtnBlock";

import SelecInput from "../../../../../Components/StateInput/SelectInput";

import { IoMdArrowForward } from "react-icons/io";
import usePouchCud from "../../../Hooks/usePouchCud";

const EditStep = ({
  id,
  doc,
  showModal,
  setShowModal,
  data,
  setData,
  fetchSteps,
}) => {
  const [users, setUsers] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(0);
  const [selectedUser, setSelectedUser] = useState(0);
  const [spaces, setSpaces] = useState([]);
  const [show, setShow] = useState(false);
  const [spteam, setSpteam] = useState(null);
  const [spaceId, setSpaceId] = useState(null);
  const [showError, setShowError] = useState(0);

  const input = useRef({ form: {}, err: {} });

  const request = useAxios();

  const { findAll, fetchById } = usePouch();

  const formErrors = useHandleFormError();

  const { user, setModalMessage, setShowSuccessModal } = useApp();

  const { update } = usePouchCud();

  useEffect(() => {
    async function getSpaces() {
      try {
        let thispc = await fetchById({
          db: "space_team",
          id: doc.space_team_id.toString(),
        });

        setSelectedSpace(thispc.space_id);
        setSelectedUser(doc.user_id);
        let spc = await findAll({
          db: "spaces",
          index: ["id"],
          selector: {},
          sort: [{ id: "asc" }],
        });
        let allspc = [{ value: 0, label: "Select Space" }];
        for (let i = 0; i < spc.length; i++) {
          let cat = spc[i];
          allspc.push({
            value: parseInt(cat.id),
            label: cat.name,
          });
        }
        setSpaces(allspc);
      } catch (error) {}
    }
    getSpaces();
  }, []);
  useEffect(() => {
    if (showModal === false) return;
    async function initialize() {
      try {
        let rms = await findAll({
          db: "space_team",
          index: ["id"],
          selector: {},
          sort: [{ id: "asc" }],
        });
        let sptm = rms.filter(
          (item) => item.space_id === parseInt(selectedSpace)
        );
        let new_users = [
          {
            label: "Select User",
            value: 0,
          },
        ];

        for (let i = 0; i < sptm.length; i++) {
          let cat = sptm[i];
          let usr = await fetchById({
            db: "team",
            id: cat.user_id.toString(),
          });

          new_users.push({
            label: `${usr.name}`,
            value: parseInt(usr.id),
          });
        }
        setUsers(new_users);

        setShow(true);
        let spt = await findAll({
          db: "space_team",
          index: ["id"],
          selector: {},
          sort: [{ id: "asc" }],
        });
        let jtl = spt.filter(
          (item) => item.space_id == parseInt(selectedSpace)
        );

        let spID = spt.filter(
          (item) =>
            item.space_id === parseInt(selectedSpace) &&
            item.user_id === parseInt(selectedUser)
        );
        setSpteam(jtl[0].id);
        setSpaceId(spID[0].id);
      } catch (e) {}
    }

    initialize();
  }, [showModal, selectedSpace, selectedUser]);

  async function submit() {
    setShowError(showError + 1);
    if (formErrors({ input })) return;

    let obj = {
      id: parseInt(doc.id),
      comments: input.current.form.comments ? input.current.form.comments : " ",
      step_no: parseInt(input.current.form.step_no),
    };

    let res = await request({
      method: "PUT",
      url: "hospital/step",
      data: obj,
    });

    if (res === "error") return;
    setModalMessage("Step Updated!");
    setShowSuccessModal(true);
    await update({ name: "visit_steps", doc: res });
    await fetchSteps();

    setShowModal(false);
  }

  if (setShow === false) return;

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Edit Step
        </h2>
        <form className="lg:w-[517px] flex flex-col gap-y-6">
          <NumberInput
            initial={doc.step_no}
            input={input}
            inputKey="step_no"
            mt={0.5}
            config={{
              required: false,
              label: "Step No",
              min: 0,
              max: data[data.length - 1].step_no,
            }}
          />

          <TextArea
            initial={doc.comments}
            input={input}
            showError={showError}
            inputKey="comments"
            mt={0.5}
            config={{
              required: false,
              label: "Comments",
              placeholder: "",
              type: "text",
              inputType: "text",
              maxChar: 1000,
              minChar: 4,
            }}
          />
          <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
            <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
              <span>Update</span>
              <span className="text-xl">
                <IoMdArrowForward />
              </span>
            </div>
          </BtnBlock>
        </form>
      </div>
    </BasicModal>
  );
};

export default EditStep;
