import { useState, useEffect } from "react";

import PatientDetails from "./PatientDetails/index";
import KinDetails from "./KinDetails/index";
import BellTopBar from "../../../../../../Components/Bars/BellTopBar";

import { useParams, useNavigate } from "react-router-dom";

import useAxios from "../../../../../../Hooks/useAxios";

const ViewPatient = () => {
  const { id } = useParams();

  const [patient, setPatient] = useState({});

  const [kins, setKins] = useState([]);

  const [relations, setRelations] = useState([]);

  const [activeKin, setActiveKin] = useState(null);

  const request = useAxios();

  const navigate = useNavigate();

  async function init() {
    let res = await request({
      method: "GET",
      url: `patient/${id}`,
    });

    if (res === "error") {
      navigate("../");
      return;
    }

    setPatient(res.patient);
    setKins(res.kins);

    let relations = res.relationships.map((doc) => ({
      value: doc.id,
      label: doc.name,
    }));
    setRelations([{ label: "Select Relationship", value: "" }, ...relations]);
    if (res.kins.length !== 0) {
      setActiveKin(0);
    }
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className=" bg-white">
      <BellTopBar
        label={`${patient.first_name || ""} ${patient.second_name || ""} ${
          patient.sir_name || ""
        }`}
      />
      <div className="grid grid-cols-2 gap-4 mb-8">
        <PatientDetails patient={patient} relations={relations} />
        <KinDetails
          kins={kins}
          setKins={setKins}
          activeKin={activeKin}
          setActiveKin={setActiveKin}
          relations={relations}
          init={init}
        />
      </div>
    </div>
  );
};

export default ViewPatient;
