import React, { useEffect, useState } from "react";

import BellTopBar from "../../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../../Components/FinalTable";

import Filter from "./components/FilterModal";

import usePouchPagination from "../../../../../Hooks/usePouchPagination";

import { useNavigate } from "react-router-dom";

import RequestsAll from "./context/RequestsContext";

import RequestsTable from "./components/Table/RequestsTable";

import useApp from "../../../../../Hooks/useApp";

const AllRequests = ({ showLive, setShowLive }) => {
  const { user } = useApp();

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const [limit, setLimit] = useState(10);

  const [data, setData] = useState([]);

  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);

  const [isFiltered, setIsFiltered] = useState(false);

  const [fixed, setFixed] = useState({ user_id: user.id });

  const [tabsData, setTabsData] = useState({});

  const db_name = "requests";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    setData,
    setTableIsLoading,
    fixed,
  });

  async function handleClearFilters() {
    await setFixed({});

    setIsFiltered(false);
  }

  return (
    <RequestsAll.Provider
      value={{
        showLive,
        setShowLive,
        limit,
        setLimit,
        data,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters: handleClearFilters,
        total: "...",
        setIsFiltered,
        showPagination,
        tabsData,
        resetQuery: handleClearFilters,
      }}
    >
      <div className="bg-ghost_white h-full pb-4">
        <BellTopBar label={"Requests"} />

        <div className="flex justify-end">
          <TableTopBar
            showSearch={false}
            showAdd={true}
            addLabel="Add request"
            AddClick={() => navigate("/dashboard/requests/add")}
            showFilter={false}
            handleClearFilters={handleClearFilters}
            isFiltered={isFiltered}
            tableIsLoading={tableIsLoading}
            showReport={false}
            changes={0}
            FilterClick={() => setOpen(true)}
            mt={2}
            mb={2}
            px={1.5}
          />
        </div>

        <RequestsTable />

        <Filter open={open} setOpen={setOpen} />
      </div>
    </RequestsAll.Provider>
  );
};

export default AllRequests;
