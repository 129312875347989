import React, { useState, useEffect } from "react";

import { colors } from "./../../Util/colors";

const SelectInput = ({
  input,
  inputKey,
  showError = 0,
  initial = "",
  mt = 0,
  mb = 0,
  config = {
    disabled: false,
    required: true,
    firstDisabled: true,
    items: [],
    label: "Text input",
  },
}) => {
  const [selectInput, setSelectInput] = useState(initial);
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [err, setErr] = useState(false);
  const [errMessage, setErrorMessage] = useState("Field Required");

  useEffect(() => {
    if (showError === 0) {
      return;
    }

    handleBlur();
  }, [showError]);

  useEffect(() => {
    input.current.form[inputKey] = initial;
  }, []);

  function handleChange(txt) {
    setSelectInput(txt);
    input.current.form[inputKey] = txt;
  }

  function handleBlur() {
    if (config.required === false) {
      return;
    }

    if (config.firstDisabled === false && selectInput === "") {
      input.current.err[inputKey] = true;
      setBorderColor("red");
      setErr(true);
      setErrorMessage("* Select Option");
      return;
    }
    input.current.err[inputKey] = false;
    setBorderColor("bright_grey");
    setErr(false);
    setErrorMessage("* Select Option");
  }

  return (
    <div
      className="flex flex-col"
      style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}
    >
      <label className=" font-pop-reg text-base">{config.label}</label>
      <select
        value={selectInput}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        className={
          "border-2 rounded-lg w-full py-2 px-8 placeholder-cadet_grey"
        }
        style={{
          borderColor: colors[borderColor],
          cursor: config.disabled ? "not-allowed" : "text",
        }}
        disabled={config.disabled}
      >
        {config.items.map((item, index) => (
          <option
            key={index}
            disabled={config.firstDisabled && index === 0 ? true : false}
            onChange={(e) => handleChange(e.target.value)}
            onBlur={handleBlur}
            value={item.value}
            className={`${
              config.firstDisabled && index === 0 ? "text-gray-400	" : ""
            }`}
          >
            {item.label}
          </option>
        ))}
      </select>
      <small
        className="text-red font-DM-Reg"
        style={{ opacity: err ? "100%" : "0%" }}
      >
        {errMessage}
      </small>
    </div>
  );
};

export default SelectInput;
