import useApp from "../../../Hooks/useApp";
let params = { sort_field: "created_at", sort_order: "desc", limit: 400 };

const useTables = () => {
  const { user } = useApp();
  return [
    {
      name: "patients",
      url: "patient",
      params,
    },
    {
      name: "hospital_queue",
      url: "hospital/records/visits",
      params: {
        ...params,
        checked_in: true,
      },
    },
    {
      name: "visits",
      url: "hospital/records/visits",
      params: {
        ...params,
      },
    },
    {
      name: "user_queu",
      url: "hospital/records/queues",
      params: {
        ...params,
        user_id: parseInt(user.id),
      },
    },
    {
      name: "visit_logs",
      url: "hospital/records/visits",
      params: {
        ...params,
        checked_in: false,
      },
    },
    {
      name: "requests",
      url: "store/requests",
      params,
    },
    {
      name: "receptionist_data",
      url: "hospital/analytics/reception",
    },
  ];
};

export default useTables;
