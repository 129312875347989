import React from "react";

import { Outlet } from "react-router-dom";

import SideBar from "./Components/SideBar/Sidebar";

import useSync from "./Hooks/useSync";

import useCouchSync from "./Hooks/useCouchSync";

const Dashboard = () => {
  useSync();
  useCouchSync();

  return (
    <div className=" w-screen h-screen overflow-clip flex flex-row">
      <SideBar />
      <div className="w-full h-full overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
