import React, { useState } from "react";
import { colors } from "./../../Util/colors";

const BtnPlain = ({
  bg = "transparent",
  textColor = "black",
  activeBgColor = "primary",
  activeTextColor = "white",
  click,
  children,
}) => {
  const [bgColor, setBgColor] = useState(bg);
  const [txtColor, setTxtColor] = useState(textColor);

  function handleMouseDown() {
    setBgColor(activeBgColor);
    setTxtColor(activeTextColor);
  }

  function handleMouseUp() {
    setBgColor(bg);
    setTxtColor(textColor);
  }

  return (
    <div
      className={`rounded-lg h-fit cursor-pointer w-fit flex flex-row justify-center items-center  px-4 py-2 active:opacity-50 font-DM-Bold text-base`}
      onClick={click}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      style={{
        color: colors[txtColor],
        backgroundColor: colors[bgColor],
      }}
    >
      {children}
    </div>
  );
};

export default BtnPlain;
