import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import { IoMdArrowForward } from "react-icons/io";

const SendPatient = ({ open = false, active = false, setShowNext }) => {
  const id = "send-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded click={() => setShowNext(true)}>
        <IoMdArrowForward />
      </BtnRounded>
      <Tooltip id={id} txt1="Send patient" txt2="to next step">
        <IoMdArrowForward />
      </Tooltip>
    </div>
  );
};

export default SendPatient;
