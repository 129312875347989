import React, { useContext, useState } from "react";

import { TableHeaderRow } from "../../../../../../../../Components/FinalTable";

import HeaderBtnSort from "../../../../../../../../Components/FinalTable/components/Btns/HeaderBtnSort";

import AllContext from "../../AllContext";

const THead = () => {
  const { handleHeaderQuery } = useContext(AllContext);

  const [order, setOrder] = useState("asc");

  function handleSort(name) {
    handleHeaderQuery(name, order);
    setOrder(order === "asc" ? "desc" : "asc");
  }

  return (
    <TableHeaderRow>
      <HeaderBtnSort
        w={90 / 3}
        label="VISIT-ID"
        click={() => handleSort("id")}
      />
      <HeaderBtnSort w={90 / 3} label="CHECK-IN DATE" />
      <HeaderBtnSort w={90 / 3} label="PATIENT" />
      
      <div style={{ width: "5%" }}></div>
    </TableHeaderRow>
  );
};

export default THead;
