import React, { useState, useEffect } from "react";

import { colors } from "./../../../../../../Util/colors";

//cartData={23:,34:}

const CartMultiSelect = ({
  doc,
  cartData = {},
  setCartData = () => {},
  mt = 0,
  mb = 0,
}) => {
  const [borderColor, setBorderColor] = useState("bright_grey");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    let keys = Object.keys(cartData);

    for (let key of keys) {
      if (doc.id == cartData[key].id) {
        setChecked(true);
        return;
      }
    }
    setChecked(false);
  }, [cartData]);

  const handleChange = () => {
    setChecked(!checked);
    let newCartData = { ...cartData };
    if (checked) {
      if (cartData[doc.id]) {
        delete newCartData[doc.id];
        setCartData(newCartData);
      }
      return;
    }

    newCartData[doc.id] = doc;

    setCartData(newCartData);
  };

  return (
    <div
      className="flex flex-col px-2"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <input
        className={`border-2 rounded-lg h-5 w-5 cursor-pointer checked:span>text-primary placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
        }}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
      />
    </div>
  );
};

export default CartMultiSelect;
