import React, { useEffect } from "react";

import { Outlet } from "react-router-dom";

const Requests = () => {
  return (
    <div className=" w-full h-full ">
      <Outlet />
    </div>
  );
};

export default Requests;
