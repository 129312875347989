const FormLabel = ({ children, label, mt = 0, mb = 0 }) => {
  return (
    <div
      className=" w-fit flex gap-x-5 items-center ml-4"
      style={{ marginTop: `${mt}em`, marginBottom: `${mb}em` }}
    >
      <div className="text-2xl text-black">{children}</div>
      <div className=" font-DM-Bold text-primary text-xl tracking-wide">
        {label}
      </div>
    </div>
  );
};

export default FormLabel;
