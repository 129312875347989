import React, { useState, useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAxios from "../../../../../../../Hooks/useAxios";

import BtnPlain from "../../../../../../../Components/Buttons/BtnPlain";

import { Table } from "../../../../../../../Components/FinalTable";

import TableTopBar from "../../../../../../../Components/FinalTable/TableTopBar";

import TContext from "./TContext";

import THead from "./THead";

import TBody from "./TBody";

import TInfo from "./TInfo";

import useApp from "./../../../../../../../Hooks/useApp";

import AddRequestContext from "../../context/AddRequestContext";

import Fuse from "fuse.js";

const InvTable = () => {
  const [changes, setChanges] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(5);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const { setShowFormModal, setModalMessage, setShowSuccessModal, user } =
    useApp();

  const request = useAxios();

  const navigate = useNavigate();

  const { id } = useContext(AddRequestContext);

  const getInitFixedItems = async (
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) => {
    setTableIsLoading(true);
    let res = await request({
      method: "GET",
      url: "fixed",
      params: {
        page,
        limit,
        sort_field: sort_field,
        sort_order: sort_order,
        ...filters,
      },
    });

    
    if (res === "error") {
      navigate("/dashboard/requests");
      return;
    }

    setTableIsLoading(false);
    
    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
    setTotal(res.total_docs);
  };

  useEffect(() => {
    getInitFixedItems(1, "created_at", "desc", {});
  }, [limit]);

  useEffect(() => {
    if (searchInput.length < 3) return;
    async function search() {
      setTableIsLoading(true);
      let res = await request({
        method: "GET",
        url: "fixed/search",
        params: {
          value: searchInput,
        },
      });

      if (res === "error"){
        navigate("/dashboard/requests");
        return;
      }

      const fuse = new Fuse([...res], {
        keys: ["name", "code", "shelf_no"],
      });

      const result = fuse.search(searchInput);

      let ids = [];
      let new_docs = [];

      for (let i = 0; i < result.length; i++) {
        if (i === limit) {
          break;
        }
        let doc = result[i].item;
        if (ids.includes(doc.id)) {
          continue;
        }
        ids.push(doc.id);
        new_docs.push(doc);
      }
      setData(new_docs);

      setShowPagination(false);
      setIsFiltered(true);
      setTableIsLoading(false);
    }

    search();
  }, [searchInput]);

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getInitFixedItems(page + 1, sortField, sortOrder);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getInitFixedItems(page - 1, sortField, sortOrder);
    }
  }

  async function handleClearFilters() {
    await getInitFixedItems(1, "created_at", "desc");
    setPage(1);
    setSearchInput("");
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  async function Submit() {
    if (id === -1) {
      setModalMessage("Select Fixed Asset");
      setShowFormModal(true);
    }

    let thedoc = {};

    for (let doc of data) {
      if (doc.id === id) {
        thedoc = doc;
        break;
      }
    }

    let res = await request({
      method: "POST",
      url: "store/request",
      data: {
        fixed_assets_id: parseInt(id),
        user_id: user.id,
        qty: parseInt(thedoc.nqty ? thedoc.nqty : 1),
      },
    });
    if (res !== "error") {
      setModalMessage("Fixed Asset Added");
      setShowSuccessModal(true);
      navigate("/dashboard/requests");
    }
  }

  return (
    <div className=" w-full mt-8">
      <TContext.Provider
        value={{
          limit,
          setLimit,
          data,
          page,
          hasNextPage,
          hasPrevPage,
          handleNext,
          handlePrev,
          total,
          setIsFiltered,
          showPagination,
        }}
      >
        <Table>
          <div className=" w-full flex justify-between mb-2 ">
            <TableTopBar
              showSearch={true}
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              showAdd={false}
              showFilter={false}
              changes={changes}
              handleClearFilters={handleClearFilters}
              isFiltered={isFiltered}
              tableIsLoading={tableIsLoading}
              showReport={false}
              mt={2}
              mb={2}
              px={1.5}
            />
          </div>
          <THead />
          <TBody />
          <TInfo />
          <div className="w-full flex justify-end">
            <BtnPlain bg="primary" textColor="white" click={Submit}>
              Make Request
            </BtnPlain>
          </div>
        </Table>
      </TContext.Provider>
    </div>
  );
};

export default InvTable;
