import FilterModal from "../../../../../../Components/Modals/FilterModal";
import CheckBox from "../../../../../../Components/Inputs/CheckBox";
import DateRangePicker from "../../../../../../Components/Inputs/DateRangePicker";
import { useRef } from "react";
import useAllRequests from "../hooks/useAllRequests";
import { isoDate, convDate2 } from "../../../../../../Util/Time";

const Filter = ({ open, setOpen }) => {
  const initial_input = {
    form: {
      is_inventory: "",
      is_fixed: "",
      from_date: "",
      to_date: "",
    },
    err: {},
  };
  const input = useRef(initial_input);

  const { setIsFiltered, handleFilterQuery, showLive } = useAllRequests();

  async function applyFilters() {
    let the_input = input.current.form;

    let inventory = {};
    let fixed = {};
    let from = {};
    let to = {};

    if (showLive) {
      if (the_input.from_date !== "") {
        from = {
          filter_start_date: isoDate(the_input.from_date),
        };
      }

      if (the_input.to_date !== "") {
        to = {
          filter_end_date: isoDate(the_input.to_date),
        };
      }

      if (the_input.is_inventory !== "") {
        inventory = {
          filter_is_inventory: true,
        };
      }
      if (the_input.is_fixed !== "") {
        inventory = {
          filter_is_inventory: false,
        };
      }
      if (the_input.is_fixed !== "" && the_input.is_inventory !== "") {
        inventory = {};
      }
    } else {
      if (the_input.is_inventory !== "") {
        inventory = {
          is_inventory: {
            $eq: true,
          },
        };
      }
      if (the_input.is_fixed !== "") {
        fixed = {
          is_inventory: {
            $eq: false,
          },
        };
      }

      if (the_input.from_date !== "") {
        from = {
          created_at: {
            $gte: isoDate(the_input.from_date),
          },
        };
      }
      if (the_input.to_date !== "") {
        to = {
          created_at: {
            $lte: isoDate(the_input.to_date),
          },
        };
      }
    }

    const index = ["created_at"];
    const sort = [{ created_at: "desc" }];
    const selector = {
      created_at: { $gte: null },
    };

    const secondarySelector = {
      ...inventory,
      ...fixed,
      ...from,
      ...to,
    };

    if (showLive) {
      handleFilterQuery(secondarySelector);
    } else {
      handleFilterQuery(index, sort, selector, secondarySelector);
    }

    setIsFiltered(true);
    input.current = initial_input;
    setOpen(false);
  }

  return (
    <FilterModal showFilter={open} setShowFilter={setOpen} apply={applyFilters}>
      <div className="flex flex-col py-6 gap-y-4">
        <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
          ITEM TYPE
        </h3>
        <div className="flex w-full flex-col font-pop-reg text-[13px] text-auro_metal_saurus  gap-y-4">
          <div className="">
            <CheckBox
              input={input}
              label={"Inventory"}
              inputKey="is_inventory"
            />
          </div>
          <div className="">
            <CheckBox
              input={input}
              label={"Fixed Assets"}
              inputKey="is_fixed"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col py-6 gap-y-4">
        <h3 className="uppercase font-DM-Reg font-normal text-sm text-auro_metal_saurus">
          date created
        </h3>
        <DateRangePicker
          input={input}
          inputKey1="from_date"
          inputKey2="to_date"
          initial1={convDate2(Date.now())}
          initial2={convDate2(Date.now())}
          mt={0}
          mb={0}
          config={{
            required: true,
            disabled: false,
            label: null,
            max: null,
            min: null,
          }}
        />
      </div>
    </FilterModal>
  );
};

export default Filter;
