import { FaHospitalUser } from "react-icons/fa";

import BtnTemplate from "../BtnTemplate";

import { useLocation, useNavigate } from "react-router-dom";

import Tooltip from "../../ToolTip";

const HospitalQueue = ({ open }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const id = "hospital-queue-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnTemplate
        label={"Queue"}
        open={open}
        active={location.pathname.includes("dashboard/hospital-queue")}
        click={() => navigate("hospital-queue")}
      >
        <FaHospitalUser />
      </BtnTemplate>
      <Tooltip
        id={id}
        txt1="Hospital Queue"
        txt2="Manage all checked-in patients"
      >
        <FaHospitalUser />
      </Tooltip>
    </div>
  );
};

export default HospitalQueue;
