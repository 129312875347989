import Tooltip from "../../../../Components/ToolTip";

import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import { AiFillEye } from "react-icons/ai";
const PatientProfile = ({ open = false, active = false, setShowPatient }) => {

  const id = "patient-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => setShowPatient(true)}
      >
        <AiFillEye />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="View patient"
        txt2="profile"
      >
        <AiFillEye />
      </Tooltip>
    </div>
  );
};

export default PatientProfile;