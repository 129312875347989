import React, { useState } from "react";

import { colors } from "../../../../Util/colors";

const BtnTemplate = ({ label, open, active, children, click }) => {
  return (
    <div
      onClick={click}
      className=" px-1 my-2 flex w-full  flex-row items-center border-l-4 gap-x-1 cursor-pointer relative"
      style={{
        borderColor: active ? colors.primary : "rgba(0,0,0,0)",
        color: active ? colors.primary : colors.cadet_grey,
      }}
    >
      <p className="text-2xl">{children}</p>
      {open ? <p className="font-pop-reg">{label}</p> : null}
    </div>
  );
};

export default BtnTemplate;
