import React, { useContext } from 'react'

import TRow from './TRow'

import TableContext from './TContext'

const TBody = () => {
  const { data, procedures } = useContext(TableContext);

  return (
    <>
      {procedures.map(doc => <TRow key={doc._id || doc.id} doc={doc}  /> )}
    </>
  )
}

export default TBody