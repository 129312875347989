import React from "react";
import BasicModal from "./../../../../../Components/Modals/BasicModal";

import BtnBlock from "./../../../../../Components/Buttons/BtnBlock";

import useAxios from "../../../../../Hooks/useAxios";

import { IoMdArrowForward } from "react-icons/io";
import useApp from "../../../../../Hooks/useApp";

const Next = ({ id, showModal, setShowModal, fetchSteps, visitDoc }) => {
  const request = useAxios();
  const { setModalMessage, setShowSuccessModal } = useApp();
  async function submit() {
    let res = await request({
      method: "POST",
      url: "hospital/step/next",
      data: {
        visit_id: parseInt(visitDoc.id),
      },
    });

    if (res === "error") return;
    setShowModal(false);
    await fetchSteps();
    setModalMessage("Patient sent to next step!");
    setShowSuccessModal(true);
  }

  return (
    <BasicModal showModal={showModal} setShowModal={setShowModal}>
      <div className="flex flex-col w-full gap-y-8 p-10">
        <h2 className="font-DM-Reg text-xl text-black text-center">
          Send Patient to next step ?
        </h2>
        <BtnBlock click={submit} mt={2.5} bg="primary" textColor="white">
          <div className="flex w-full text-[16px] items-center justify-center px-2 py-1 space-x-2">
            <span>Continue</span>
            <span className="text-xl">
              <IoMdArrowForward />
            </span>
          </div>
        </BtnBlock>
      </div>
    </BasicModal>
  );
};

export default Next;
