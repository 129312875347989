import React from "react";

import {
  TableCell,
  TableText,
  TableRow,
} from "../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../Util/Time";
import TableActions from "./TableActions";
import Badge from "../../../../../../Components/Badges/Badge";

const TRow = ({ doc }) => {
  return (
    <>
      <TableRow key={doc.id}>
        <TableCell w={80 / 8}>
          <TableText label={doc.procedure_id || doc.scheme_procedure_id} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText
            label={` ${
              doc.scheme_procedure?.procedures?.name ||
              doc.procedures?.name ||
              "---"
            }`}
          />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText
            label={
              doc.team_visit_procedure_requested_byToteam &&
              doc.team_visit_procedure_requested_byToteam.name
            }
          />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText
            label={
              doc.team_visit_procedure_performed_byToteam &&
              doc.team_visit_procedure_performed_byToteam.name
            }
          />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={doc.to_pay} />
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={doc.payment_method} />
        </TableCell>
        <TableCell w={80 / 8}>
          {doc.procedure_status == "pending" ? (
            <Badge label={doc.procedure_status} bg={"pale_spring_bud"} />
          ) : doc.procedure_status == "canceled" ? (
            <Badge label={doc.procedure_status} bg={"pale_pink"} />
          ) : doc.procedure_status == "complete" ? (
            <Badge label={doc.procedure_status} bg={"bright_grey"} />
          ) : (
            " "
          )}
        </TableCell>
        <TableCell w={80 / 8}>
          <TableText label={convDate(doc.created_at)} />
        </TableCell>
        <TableCell>
          <TableActions doc={doc} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default TRow;
