import React, { useEffect, useState, useContext } from "react";

import {
  TableCell,
  TableRow,
  TableText,
} from "../../../../../../../../Components/FinalTable";

import { convDate } from "../../../../../../../../Util/Time";

import Badge from "../../../../../../../../Components/Badges/Badge";

import TActions from "./TActions";

const TRow = ({ doc }) => {
  return (
    <TableRow>
      <TableCell w={85 / 3}>
        <TableText label={`vis-${doc.visit_steps.visits.id}`} />
      </TableCell>
      <TableCell w={85 / 3}>
        <TableText label={convDate(doc.visit_steps.visits.created_at)} />
      </TableCell>
      <TableCell w={85 / 3}>
        <TableText
          label={`${doc.visit_steps.visits.patients.first_name} ${doc.visit_steps.visits.patients.second_name}`}
        />
      </TableCell>
      
    </TableRow>
  );
};

export default TRow;
