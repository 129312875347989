import React, { useState, useEffect } from "react";

import { colors } from "./../../../../Util/colors";
// const [checked_id,setCheched_id]=useState(-1)

const SingleCheckBox = ({ checked_id, setCheckedId, id, mt = 0, mb = 0 }) => {
  const [borderColor, setBorderColor] = useState("bright_grey");

  const handleChange = () => {
    if (checked_id === id) {
      setCheckedId(-1);
      return;
    }
    setCheckedId(id);
  };

  return (
    <div
      className="flex flex-col px-2"
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
      }}
    >
      <input
        className={`border-2 rounded-lg h-5 w-5 cursor-pointer checked:span>text-primary placeholder-cadet_grey `}
        onFocus={() => setBorderColor("primary")}
        style={{
          borderColor: colors[borderColor],
        }}
        type="checkbox"
        checked={checked_id === id ? true : false}
        onChange={handleChange}
      />
    </div>
  );
};

export default SingleCheckBox;
