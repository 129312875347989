import { colors } from "./../../../Util/colors";

const TableRow = ({
  children,
  showDivider = true,
  h = 1,
  bg = "transparent",
}) => {
  return (
    <div
      className="w-full flex flex-row gap-x-5 py-4 items-center "
      style={{
        borderColor: "#DEE2E6",
        // borderTopWidth: h,
        borderBottomWidth: showDivider ? h : 0,
        backgroundColor: colors[bg] || "transparent",
      }}
    >
      {children}
    </div>
  );
};

export default TableRow;
