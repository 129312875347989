const BasicDropDown = ({ children, isVisible = true }) => {
  if (isVisible === false) {
    return null;
  }
  return (
    <div className="flex flex-col gap-y-3 w-fit px-4 py-2 rounded-md z-50 shadow-lg bg-white">
      {children}
    </div>
  );
};

export default BasicDropDown;
