
import Tooltip from "../../../Components/ToolTip";
import BtnRounded from "../../../../../Components/Buttons/BtnRounded";
import {  AiOutlineFile } from "react-icons/ai";

const VisitDetail = ({ open = false, active = false, setShowFile }) => {

  const id = "visit-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => setShowFile(true)}
      >
        <AiOutlineFile />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="View visit"
        txt2="details"
      >
        <AiOutlineFile />
      </Tooltip>
    </div>
  );
};

export default VisitDetail;
