import React, { useState } from "react";
import { Table } from "../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { FaPlus } from "react-icons/fa";
import TableContext from "./TContext";

const ProcedureTable = ({
  procedures,
  setProcedures,
  handleUpdate,
  visitDetail,
}) => {
  const [limit, setLimit] = useState(25);
  const [data, setData] = useState([]);
  const [vpic, setvPic] = useState(null);
  const [showPagination] = useState(true);
  const navigate = useNavigate();

  const [isFiltered, setIsFiltered] = useState(false);
  const [setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});
  const db_name = "visit_procedure";
  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        data,
        procedures,
        setProcedures,
        handleUpdate,
        setData,
        vpic,
        setvPic,

        isFiltered,

        setIsFiltered,
        showPagination,
        setFixed,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">Procedures</p>
          <div className="">
            {visitDetail?.default_payment_method == "Cash" ? (
              <BtnPlain
                bg="primary"
                textColor="white"
                activeBgColor="secondary"
                mt={2}
                click={() => navigate("add/procedure/cash")}
                className="btn"
              >
                <span className="px-2 font-light text-xl">
                  <FaPlus />
                </span>
                <span className=" font-pop-reg"> Add Procedure Cash</span>
              </BtnPlain>
            ) : visitDetail?.default_payment_method == "Insurance" ? (
              <div className="flex gap-x-5">
                <BtnPlain
                  bg="primary"
                  textColor="white"
                  activeBgColor="secondary"
                  mt={2}
                  click={() => navigate("add/procedure/cash")}
                  className="btn"
                >
                  <span className="px-2 font-light text-xl">
                    <FaPlus />
                  </span>
                  <span className=" font-pop-reg">Add Procedure Cash</span>
                </BtnPlain>

                <BtnPlain
                  bg="primary"
                  textColor="white"
                  activeBgColor="secondary"
                  mt={2}
                  click={() => navigate("add/procedure/insurance")}
                  className="btn"
                >
                  <span className="px-2 font-light text-xl">
                    <FaPlus />
                  </span>
                  <span className=" font-pop-reg">Add Procedure Insurance</span>
                </BtnPlain>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Table>
          <THead />
          <TBody />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default ProcedureTable;
