import React from "react";
import BtnPlain from "../Buttons/BtnPlain";
import BtnBlock from "../Buttons/BtnBlock";
import SearchInput from "../Inputs/SearchInput";
import { BiPlus, BiFilter, BiDownload } from "react-icons/bi";

import PingBadge from "./../../Components/Badges/PingBadge";

import { ThreeDots } from "react-loader-spinner";

import { colors } from "./../../Util/colors";

import { IoReload } from "react-icons/io5";

const TableTopBar = ({
  FilterClick,
  ReportClick,
  showAdd = false,
  showFilter = true,
  showReport = true,
  changes = 0,

  tableIsLoading = true,
  handleClearFilters,
  isFiltered = false,
  AddClick,
  addLabel = "Add",
  showSearch = true,
  handleLiveStream = () => {},
  searchInput,
  setSearchInput,
  mt = 0,
  mb = 0,
  px = 0,
}) => {
  return (
    <div
      className={`w-full flex flex-col md:flex-row items-end md:items-center justify-center ${
        showSearch ? "md:justify-between" : "md:justify-end"
      } gap-y-5`}
      style={{
        marginTop: `${mt}em`,
        marginBottom: `${mb}em`,
        paddingLeft: `${px}em`,
        paddingRight: `${px}em`,
      }}
    >
      {showSearch && (
        <SearchInput
          input={searchInput}
          setInput={setSearchInput}
          placeholder={"Search"}
        />
      )}
      <div className="flex gap-x-4 items-center">
        <ThreeDots
          height="40"
          width="40"
          radius="9"
          color={colors.primary_accent}
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={tableIsLoading}
        />

        {changes === 0 ? null : (
          <PingBadge
            label="live stream"
            bg="transparent"
            textColor="charcol"
            click={handleLiveStream}
          />
        )}
        <BtnPlain click={handleLiveStream} bg="transparent">
          <IoReload />
        </BtnPlain>

        {isFiltered ? (
          <BtnPlain click={handleClearFilters} bg="transparent">
            <div className="flex items-center space-x-2 text-[16px]">
              <span>Clear filters ?</span>
            </div>
          </BtnPlain>
        ) : null}

        {showFilter && (
          <BtnPlain click={FilterClick} bg="white">
            <div className="flex items-center space-x-2 text-[16px]">
              <BiFilter />
              <span>Filter</span>
            </div>
          </BtnPlain>
        )}

        {showReport && (
          <BtnPlain click={ReportClick} bg="white">
            <div className="flex items-center space-x-2 text-[16px]">
              <BiDownload />
              <span>Report</span>
            </div>
          </BtnPlain>
        )}

        {showAdd ? (
          <div className="pl-5">
            <BtnPlain bg="primary" textColor="white" click={AddClick}>
              <div className="flex flex-nowrap w-full text-[16px] items-center px-2 py-1 space-x-2">
                <BiPlus />
                <span>{addLabel}</span>
              </div>
            </BtnPlain>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableTopBar;
