import useAllRequests from "../../hooks/useAllRequests";

import TRow from "./Row";

const RequestsLogs = () => {
  const { data } = useAllRequests();

  return (
    <>
      {data?.map((doc) => (
        <TRow key={doc.id || doc._id} doc={doc} />
      ))}
    </>
  );
};

export default RequestsLogs;
