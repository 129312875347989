import React from "react";

const CartCard = ({ doc = {}, setCartData = () => {} }) => {
  function closeItem() {
    setCartData((c) => {
      let newItems = { ...c };
      delete newItems[doc.id];
      return newItems;
    });
  }

  return (
    <div className="w-full p-5 rounded-2xl shadow-lg leading-8 border border-gray-200 mt-3">
      <div className="">
        <div className="w-full">
          <p className="text-gray-900 font-medium px-2 py-1">{`Name : ${doc.name}`}</p>
          <p className="text-gray-900 font-medium border-t px-2 py-1">
            {`Code : ${doc.code}`} 0001
          </p>
          <p className="text-gray-900 font-medium border-t border-b py-1 px-2">{`Regular Price : ${doc.regular_price}`}</p>
        </div>
        <p
          className="w-32 mx-auto flex justify-center items-center text-white bg-primary cursor-pointer rounded-md mt-5"
          onClick={closeItem}
        >
          Remove
        </p>
      </div>
    </div>
  );
};

export default CartCard;
