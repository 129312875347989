import { useState } from "react";
import Header from "./TableHeader";
import RequestsLogs from "./RequestLogs";
import Infosection from "./Infosection";
import Tabs from "./Tabs";

import { Table } from "../../../../../../../Components/FinalTable";

const RequestsTable = () => {
  return (
    <div className=" ml-5 mt-5 p-5 mb-4 mr-5 bg-white rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
      <Table>
        <div className="w-full flex justify-start">
          <Tabs />
        </div>
        <Header />
        <RequestsLogs />
        <Infosection />
      </Table>
    </div>
  );
};

export default RequestsTable;
