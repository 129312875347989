import React, { useState } from "react";

import { BiSearch } from "react-icons/bi";

const SearchInput2 = ({
  placeholder = "Search",
  disabled = false,
  setInput,
  input,
  mt = 0,
  mb = 0,
}) => {
  return (
    <div
      className="relative"
      style={{ width: "2em", marginTop: `${mt}em`, marginBottom: `${mb}em` }}
    >
      <input
        className={`border-2 rounded-lg border-bright_grey focus:border-primary  py-2 pr-5 pl-10 placeholder-cadet_grey flex `}
        type={"text"}
        placeholder={placeholder}
        value={input}
        disabled={disabled}
        onChange={(e) => setInput(e.target.value)}
      />
      <div
        className="absolute cursor-pointer top-0 h-full flex items-center pl-4 active:text-primary"
        style={{ width: "2em" }}
      >
        <BiSearch />
      </div>
    </div>
  );
};

export default SearchInput2;
