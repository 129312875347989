import Tooltip from "../../../../Components/ToolTip";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import {  FaAmbulance } from "react-icons/fa";

const PatientVitals = ({ open = false, active = false, setShowVitals }) => {

  const id = "vitals-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => setShowVitals(true)}
      >
        <FaAmbulance />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="View vitals"
        txt2=""
      >
        <FaAmbulance />
      </Tooltip>
    </div>
  );
};

export default PatientVitals;
