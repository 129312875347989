import Table from "./Table";

const AddInv = () => {
  return (
    <div className=" w-full">
      <Table />
    </div>
  );
};

export default AddInv;
