import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import FormScreen from "../../../../../Components/Templates/FormScreen";

import Stepper from "./Components/Stepper";

import Forms from "./Components/Forms";

import FormLable from "../../../../../Components/Label/FormLabel";

import AddRequestContext from "./context/AddRequestContext";

import { useNavigate } from "react-router-dom";

const AddRequest = () => {
  const [step, setStep] = useState(-1);
  const [itemType, setItemType] = useState("inv");
  const [id, setId] = useState(-1);
  const [qty, setQty] = useState(0);

  const navigate = useNavigate();

  function close(){
    navigate("/dashboard/requests/")
  }
  

  function handleBack() {
    setStep(-1);
  }

  return (
    <AddRequestContext.Provider
      value={{
        itemType,
        setItemType,
        setStep,
        setId,
        id,
        qty,
        setQty,
      }}
    >
      <div className="h-[100vh]">
        <FormScreen backBtn={true} back={handleBack}>
          <div className="bg-white flex gap-x-0 items-center py-5 shadow-md">
            <span className="text-3xl pl-5">
              <AiOutlinePlusCircle />
            </span>
            <FormLable label="Add New Request" />
          </div>

          <div className="mt-5">
            <Stepper currentStep={step} close={close}/>
          </div>
          <div className=" w-full px-32">
            <Forms itemType={itemType} step={step} setStep={setStep} />
          </div>
        </FormScreen>
      </div>
    </AddRequestContext.Provider>
  );
};

export default AddRequest;
