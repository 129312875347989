import React, { useState } from "react";
import DropDown from "./DropDown";

import TableText from "./TableText";

const InfoCell = ({
  items = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 15, value: 15 },
    { label: 20, value: 20 },
    { label: 25, value: 25 },
  ],
  limit,
  setLimit,
  total = " ....",
  docs = [],
}) => {
  return (
    <div>
      <p className=" font-thin mb-3 ">{`Showing ${
        docs.length || limit
      } of ${total}`}</p>
      <div className="flex items-center gap-x-4">
        <DropDown items={items} input={limit} setInput={setLimit} />
        <TableText label="Entries per page" />
      </div>
    </div>
  );
};

export default InfoCell;
