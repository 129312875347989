import { useState, useEffect } from "react";

import FormScreen from "../../../../../Components/Templates/FormScreen";

import Forms from "./Components/Forms";

import FormLabel from "../../../../../Components/Label/FormLabel";

import Steps from "./Components/Steps";

import AddContext from "./AddContext";

import { MdSick } from "react-icons/md";

import usePouch from "../../../../../Hooks/usePouch";

import { useParams, useNavigate } from "react-router-dom";

import useApp from "../../../../../Hooks/useApp";

const Edit = () => {
  const [step, setStep] = useState(-1);

  const [show, setShow] = useState(false);

  const [patient, setPatient] = useState({
    first_name: "",
    second_name: "",
    sir_name: "",
    email: "",
    phone: "",
    id_no: "",
    id: null,
  });

  const [kin, setKin] = useState({
    name: "",
    phone: "",
    relationship: "",
    gender: "",
    patient_id: "",
  });

  const [pic, setPic] = useState(null);

  const { fetchById } = usePouch();

  const { id } = useParams();

  const navigate = useNavigate();

  const { setIsLoading } = useApp();

  useEffect(() => {
    async function initialize() {
      try {
        setIsLoading(true);
        let doc = await fetchById({ db: "patients", id });
        setPatient(doc);
        setShow(true);
      } catch (error) {
        navigate("../");
      } finally {
        setIsLoading(false);
      }
    }
    initialize();
  }, []);

  function handleBack() {
    if (step === -1) return;
    setStep((c) => c - 1);
  }

  if (show === false) {
    return null;
  }

  return (
    <AddContext.Provider
      value={{ step, setStep, patient, setPatient, pic, setPic, kin, setKin }}
    >
      <FormScreen backBtn={true} back={handleBack}>
        <FormLabel label="Edit Patient" mt={2} mb={2}>
          <MdSick />
        </FormLabel>
        <Steps currentStep={step} close={() => navigate("../")} />
        <div className="w-full flex flex-row justify-center items-center">
          <Forms step={step} setStep={setStep} />
        </div>
      </FormScreen>
    </AddContext.Provider>
  );
};

export default Edit;
