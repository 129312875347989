import Tooltip from "../../../../Components/ToolTip";
import BtnRounded from "../../../../../../Components/Buttons/BtnRounded";

import {  FaHistory } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const PatientHistory = ({ open = false, active = false, p_id  }) => {
    const navigate = useNavigate()
  const id = "history-tooltip";

  return (
    <div data-tip data-for={id} className="w-full">
      <BtnRounded
        
        click={() => navigate(`/dashboard/medical-history/${p_id}`)}
      >
        <FaHistory />
      </BtnRounded>
      <Tooltip
        id={id}
        txt1="View Patient"
        txt2="Medical History"
      >
        <FaHistory />
      </Tooltip>
    </div>
  );
};

export default PatientHistory;
